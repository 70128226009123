<script>
import Layout from "@/views/layouts/main.vue";
import PageHeader from "@/components/page-header.vue";
import axios from "axios";
import {paginationHelper} from "@/helpers/pagination-helper";
import {dateUtil} from "@/helpers/date-util";
import {errorCatcher} from "@/helpers/error-catcher";
import {required} from "vuelidate/lib/validators";
import {swalHelper} from "@/helpers/swal-helper";
import Swal from "sweetalert2";
import {jsonUtil} from "@/helpers/json-util";

/**
 * Industries Component
 */
export default {
  computed: {
    dateUtil() {
      return dateUtil
    },
    paginationHelper() {
      return paginationHelper
    }
  },

  components: {
    Layout,
    PageHeader
  },

  data() {
    return {
      submitted: false,

      table: {
        rows: 0,
        totalRows: 0,
        currentPage: 1,
        perPage: 100,
        pageOptions: [5, 10, 25, 50, 100],
        filter: null,
        filterOn: [],
        sortBy: "name",
        sortDesc: false
      },

      inputPage: "",

      historyId: "",

      history: null,

      auction: null,
      allegroAccount: null,
      shop: null,
      product: null,
      productVariation: null,

      loading: false,
    };
  },

  validations: {
    historyId: { required }
  },

  methods: {

    getItems() {
      return [
        {
          text: "ecat"
        },
        {
          text: this.$t('allegro-history-auctions.title'),
          active: true
        }
      ]
    },

    getFields() {
      return [
        { key: "id", label: 'ID' },
        { key: "type", label: 'Typ' },
        // { key: "newValue", label: 'Nowa wartość' },
        // { key: "oldValue", label: 'Stara wartość' },
        { key: "message", label: 'Wiadomość' },
        { key: "occurredAt", label: this.$t('table.created-at'), formatter: dateUtil.asDateTime },
      ]
    },

    getAllegroStatusBadge() {
      switch (this.auction.status) {
        case "PREPARED_TO_SEND":
          return "info-warning"
        case "SEND_ERROR":
          return "info-danger"
        case "ACTIVE":
          return "info-green"
        case "ENDED_BY_SYSTEM":
          return "info-danger"
        case "ENDED_BY_USER":
          return "info-danger"
      }
    },

    getAllegroStatusName() {
      switch (this.auction.status) {
        case "PREPARED_TO_SEND":
          return "W trakcie wysyłania"
        case "SEND_ERROR":
          return "Błąd"
        case "ACTIVE":
          return "Aktywna"
        case "ENDED_BY_SYSTEM":
          return "Zakończona przez System"
        case "ENDED_BY_USER":
          return "Zakończona przez Użytkownika"
      }
    },

    ean() {
      if (!this.product.ean) {
        return []
      }

      return jsonUtil.asArray(this.product.ean).filter(element => element)
    },

    eanString() {
      const ean = this.ean()
      if (ean && ean.length > 0) {
        if (ean[0]) {
          return ean[0].toString()
        }
      }

      return "-"
    },

    async loadAuction() {
      this.$v.historyId.$touch();
      if (this.$v.historyId.$invalid) {
        return;
      }

      this.loading = true

      try {
        const { data } = await axios.get(`/allegro/auction/fetch`, {
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json'
          },
          data: {},
          params: {
            allegroAuctionId: this.historyId,
          }
        });

        this.auction = data
        await this.loadAllegroAccount()
        await this.loadAllegroHistory()
        await this.loadProduct()
        await this.loadProductVariation()
      } catch (error) {
        errorCatcher.catchErrors(error)
      }

      this.loading = false
    },

    async loadAllegroAccount() {
      try {
        const { data } = await axios.get(`/allegro/fetch`, {
          data: {},
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json'
          },
          params: {
            'id': this.auction.allegroAccountId
          }
        });

        this.allegroAccount = data
        await this.loadShop()
      } catch (error) {
        errorCatcher.catchErrors(error)
      }
    },

    async loadShop() {
      try {
        const { data } = await axios.get(`/shop/${this.allegroAccount.shopId}`, {
          data: {},
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json'
          }
        });

        this.shop = data
      } catch (error) {
        errorCatcher.catchErrors(error)
      }
    },

    async loadProduct() {
      try {
        const { data } = await axios.get(`/product/fetch`, {
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json'
          },
          data: {},
          params: {
            productId: this.auction.productId
          }
        })

        this.product = data
      } catch (error) {
        errorCatcher.catchErrors(error)
      }
    },

    async loadProductVariation() {
      try {
        const { data } = await axios.get(`/product/variation/fetch`, {
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json'
          },
          data: {},
          params: {
            "productVariationId": this.auction.productVariationId
          }
        })

        this.productVariation = data
      } catch (error) {
        errorCatcher.catchErrors(error)
      }
    },

    async loadAllegroHistory() {
      this.loading = true

      let page = this.table.currentPage - 1;
      if (page > 0) {
        page = this.table.currentPage * this.table.perPage - this.table.perPage;
      }

      const { data } = await axios.get(`/allegro/auction/${this.auction.id}/log`, {
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json'
        },
        data: {}
      });

      this.history = data
      this.table.totalRows = data.length;
      this.table.rows = data.length;

      // this.history = data.history
      // this.table.totalRows = data.count;
      // this.table.rows = data.count;

      if (this.mounted) {
        this.$refs.table.refresh()
      }

      this.loading = false
    },

    endAuction() {
      this.submitted = true;
      swalHelper.yesOrNo(() => {
        axios.post(`/allegro/auction/end/${this.auction.id}`, {
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json'
          },
          data: {}
        }).then(({ data }) => {
          this.$bvToast.toast('Pomyślnie usunięto aukcję', {
            title: this.$t('message.success'),
            variant: 'success',
            solid: true
          });

          this.auction = data
        }).catch((error) => {
          errorCatcher.catchErrors(error)
          this.submitted = false;
        });
      });
    },

    endAuctions() {
      axios.get(`/allegro/auction/fetch/auction-ids`, {
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json'
        },
        data: {},
        params: {
          "productIds": [this.auction.productId].join(",")
        }
      }).then(({ data }) => {
        const ids = data.ids

        swalHelper.yesOrNoCustomizable(() => {
          this.endAuctionsWithIds(ids)
        }, "warning", "Czy na pewno?", `${ids.length} aukcji zostanie zakończonych`, "Zakończ", "Anuluj", "btn btn-danger", "btn btn-secondary")
      }).catch((error) => {
        errorCatcher.catchErrors(error)
      });
    },

    endAuctionsWithIds(ids) {
      const json = JSON.stringify({
        "auctionIds": ids
      });

      axios.post(`/allegro/auction/end-auctions`, json, {
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json'
        },
      }).then(({ data }) => {
        Swal.fire("Sukces!", `${data.countEnded} aukcji zostało zakończonych z ${ids.length} możliwych`, "success");
      }).catch((error) => {
        errorCatcher.catchErrors(error)
      })
    }

    // deleteAuction() {
    //   this.submitted = true;
    //
    //   swalHelper.yesOrNo(() => {
    //     axios.delete(`/allegro/auction/${this.auction.id}`, {
    //       headers: {
    //         'Content-Type': 'application/json',
    //         Accept: 'application/json'
    //       },
    //       data: {}
    //     }).then(() => {
    //       this.$bvToast.toast('Pomyślnie usunięto aukcję', {
    //         title: this.$t('message.success'),
    //         variant: 'success',
    //         solid: true
    //       });
    //     }).catch((error) => {
    //       errorCatcher.catchErrors(error)
    //       this.submitted = false;
    //     });
    //   });
    // }

  },

};
</script>

<template>
  <Layout>
    <PageHeader :title="$t('allegro-history-auctions.title')" :items="getItems()" />
    <div class="row">
      <div class="col-4">
        <div class="card">
          <div class="card-body">
            <div>
              <label>Szukaj po ID Aukcji Allegro</label>
              <input id="id" v-model="historyId" type="text" class="form-control" placeholder="Szukaj..." :class="{ 'is-invalid': $v.historyId.$error }" />
              <div v-if="!$v.historyId.required" class="invalid-feedback">{{ $t('message.required') }}</div>

              <div class="py-2">
                <a class="btn btn-success mb-2" @click="loadAuction">
                  Szukaj
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="col-12" v-if="auction && !loading">
      </div>

      <div class="col-4" v-if="auction && !loading">
        <div class="card">
          <div class="card-body">
            <div class="table-responsive">
              <table class="table table-hover table-bordered">
                <tbody><tr>
                  <th>Atrybut</th>
                  <th>Wartość</th>
                </tr>
                <tr>
                  <td>Id Oferty</td>
                  <td>{{  auction.id }}</td>
                </tr>
                <tr>
                  <td>Tytuł</td>
                  <td>{{ auction.title }}</td>
                </tr>
                <tr>
                  <td>Cena</td>
                  <td>{{ auction.price.toFixed(2) }} PLN</td>
                </tr>
                <tr>
                  <td>EAN</td>
                  <td>{{  eanString() }}</td>
                </tr>
                <tr>
                  <td>Ilość</td>
                  <td>{{  auction.quantity }}</td>
                </tr>
                <tr>
                  <td>SKU</td>
                  <td><a :href="product ? ('/dashboard/admin/product/show/' + product.id) : ''" target="_blank">{{  productVariation ? 'W' + productVariation.sku : '' }}</a></td>
                </tr>
                <tr>
                  <td>Id Wariacji</td>
                  <td>{{  auction.productVariationId }}</td>
                </tr>
                <tr v-if="allegroAccount">
                  <td>Konto allegro</td>
                  <td><a :href="`https://allegro.pl/uzytkownik/${allegroAccount.name}`" target="_blank">{{ allegroAccount.name }}</a></td>
                </tr>
                <tr>
                  <td>Sklep</td>
                  <td><a :href="shop ? shop.domain : ''" target="_blank">{{  shop ? shop.name : "" }}</a></td>
                </tr>
                <tr>
                  <td>Status</td>
                  <td><span class="badge" :class="getAllegroStatusBadge()">{{ getAllegroStatusName() }}</span></td>
                </tr>
                <tr>
                  <td>Data utworzenia</td>
                  <td>{{ dateUtil.asDateTimeNormal(auction.createdAt) }}</td>
                </tr>
                <tr>
                  <td>Data aktualizacji</td>
                  <td>{{  auction.updatedAt ? dateUtil.asDateTimeNormal(auction.updatedAt) : "-" }}</td>
                </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>

        <div class="col-12">

          <div class="button-items" v-if="auction">
            <a v-if="auction.status === 'IN_ALLEGRO_ACTIVATED' && !loading" class="btn btn-danger" @click="endAuction">Usuń aukcję</a>
            <a v-if="!loading" class="btn btn-danger" @click="endAuctions">Usuń wszystkie aukcje tego produktu (ostrożnie)</a>
          </div>

          <template v-if="history && !loading">
            <div class="py-4">
            <div class="card">
              <div class="card-body">
                  <div class="py-4">
                    <div class="row mt-4">
                      <div class="col-sm-12 col-md-6">
                        <div id="tickets-table_length" class="dataTables_length">
                          <label class="d-inline-flex align-items-center">
                            {{ $t('table.show') }}&nbsp;
                            <b-form-select v-model="table.perPage" size="sm" :options="table.pageOptions"></b-form-select>&nbsp;{{ $t('table.entries') }}
                          </label>
                        </div>
                      </div>

                      <!-- Search -->
                      <div class="col-sm-12 col-md-6">
                        <div id="tickets-table_filter" class="dataTables_filter text-md-right">
                          <label class="d-inline-flex align-items-center">
                            {{ $t('table.search') }}
                            <b-form-input
                                v-model="table.filter"
                                type="search"
                                class="form-control form-control-sm ml-2"
                            ></b-form-input>
                          </label>
                        </div>
                      </div>
                      <!-- End search -->
                    </div>

                    <div class="row">
                      <div class="col">
                        <div class="float-right">
                          <label class="d-inline-flex align-items-center mx-2">
                            <b-form-input v-model="inputPage" class="form-control form-control-sm ml-2" />
                          </label>
                          <b-button variant="primary" @click="paginationHelper.setCurrentPage(table, inputPage)">{{ $t('table.go-to-page') }}</b-button>
                        </div>
                      </div>
                    </div>

                    <div class="row py-3">
                      <div class="col">
                        <div class="dataTables_paginate paging_simple_numbers float-right">
                          <ul class="pagination pagination-rounded mb-0">
                            <b-pagination v-model="table.currentPage" :total-rows="table.rows" :per-page="table.perPage"></b-pagination>
                          </ul>
                        </div>
                      </div>
                    </div>

                    <div class="table-responsive">
                      <b-table

                          ref="table"
                          :items="history"
                          :fields="getFields()"
                          responsive="sm"
                          :per-page="table.perPage"
                          :current-page="table.currentPage"
                          :sort-by.sync="table.sortBy"
                          :sort-desc.sync="table.sortDesc"

                          :filter-included-fields="table.filterOn"
                          @filtered="elements => paginationHelper.onFiltered(table, elements)"
                          :empty-text="$t('message.no-elements')"
                          :empty-filtered-text="$t('message.no-records')" :show-empty="true">
                      </b-table>
                    </div>

                    <div class="row">
                      <div class="col">
                        <div class="float-left">
                          <p>{{ $t('table.entries-footer', { 'amount': paginationHelper.getElementsAmount(table), 'elements': paginationHelper.getElementsCount(table), 'all': table.totalRows }) }}</p>
                        </div>

                        <div class="dataTables_paginate paging_simple_numbers float-right">
                          <ul class="pagination pagination-rounded mb-0">
                            <!-- pagination -->
                            <b-pagination v-model="table.currentPage" :total-rows="table.rows" :per-page="table.perPage"></b-pagination>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
              </div>
            </div>
            </div>
          </template>
          <template v-else-if="loading">
            <div class="text-center py-3">
              <h5>{{ $t('message.loading') }}</h5>
              <span aria-hidden="true" class="m-2 spinner-border text-primary"></span>
            </div>
          </template>
        </div>
    </div>

  </Layout>
</template>